import JoditEditor from 'jodit-react';
import { observer } from 'mobx-react';
import { useMemo, useRef } from 'react';

interface RichTextProps {
  value?: string;
  config?: Record<string, any>;
  onChange?: (value: string) => void;
}

const RichText = ({ value, config, onChange, ...rest }: RichTextProps) => {
  const editor = useRef();
  const _config = useMemo(
    () => ({
      placeholder: '请输入',
      buttons: ['bold', 'italic', 'source'],
      disablePlugins: ['paste'],
      hidePoweredByJodit: true,
      ...config,
    }),
    [config],
  );
  return (
    <JoditEditor
      ref={editor}
      value={value}
      config={_config}
      onChange={onChange}
      {...rest}
    />
  );
};
export default observer(RichText);
