import { type BaseData, getHtmlText, request, transformKeys } from '@/utils';
import { type FormInstance, message } from 'antd';
import moment from 'dayjs';
import { action, observable } from 'mobx';

export default class EditorModal {
  @observable public visible = false;
  @observable public type = 'add';
  @observable public ref: FormInstance;
  @observable public showType = 0;
  @observable public effectTIme = true;
  @observable public saveLoading = false;
  @observable public id: number;
  @observable public projectList = [];
  public terminal = [
    {
      label: '主播端',
      value: '1',
    },
    {
      label: '雇主端',
      value: '2',
    },
  ];

  private parent = null;

  constructor(parent) {
    this.parent = parent;
  }

  @action public onShow = (type?: 'add' | 'edit', row?) => {
    this.visible = true;
    this.type = type;
    this.showType = row?.linkType || 0;
    if (row) {
      this.id = row.id;

      const terminalValue = this.terminal.find((item) => item.value == row.terminal);
      if (row.linkType !== undefined) {
        this.showType = row.linkType;
      }
      this.setEffectTime(row.tackEffectType || 0);

      if (row.terminal) {
        this.getMealList(row.terminal);
      }

      const formattedRow = {
        ...row,
        terminal: terminalValue || { label: '', value: '' },
        mealId: row.mealId ? { label: row.mealName, value: row.mealId } : { label: '', value: '' },
        text: undefined,
      };
      if (row.text) {
        getHtmlText(row.text).then((html) => {
          this.ref.setFieldsValue({
            text: html,
          });
        });
      }

      if (formattedRow.tackEffectStartTime && formattedRow.tackEffectEndTime) {
        const startTime = moment(formattedRow.tackEffectStartTime);
        const endTime = moment(formattedRow.tackEffectEndTime);
        formattedRow.time = [startTime, endTime];
      }

      setTimeout(() => {
        this.ref.setFieldsValue(formattedRow);
      });
    }
  };
  @action public onCancel = () => {
    this.visible = false;
    this.ref.resetFields();
  };
  @action public setRef = (ref: FormInstance): void => {
    this.ref = ref;
  };

  @action public onClose = () => {
    this.visible = false;
    this.effectTIme = true;
    this.ref.resetFields();
  };
  @action public onSubmit = async () => {
    const isValidated = await this.ref.validateFields();
    if (!isValidated) {
      return;
    }
    this.saveLoading = true;

    const values = this.ref.getFieldsValue();

    const postParams = {
      ...values,
    };

    if (postParams.time) {
      postParams.tackEffectStartTime = postParams.time[0].format('YYYY-MM-DD HH:mm:ss');
      postParams.tackEffectEndTime = postParams.time[1].format('YYYY-MM-DD HH:mm:ss');
      postParams.time = undefined;
    }

    if (postParams.terminal) {
      postParams.terminal = postParams.terminal.value;
    }
    if (postParams.mealId) {
      postParams.mealId = postParams.mealId.value;
    }
    if (this.type === 'edit') {
      postParams.id = this.id;
    }

    const apiUrl =
      this.type === 'edit' ? '/pbb/platform/sys/advertisement/update' : '/pbb/platform/sys/advertisement/add';
    request({
      url: apiUrl,
      method: 'POST',
      data: { ...postParams },
    })
      .then((result) => {
        message.success('操作成功');
        this.ref.resetFields();
        this.parent.mainStore.onQuery();
        this.onClose();
      })
      .finally(() => {
        this.saveLoading = false;
      });
  };

  @action public showInput = (type: number) => {
    this.showType = type;
  };

  @action public setEffectTime = (type: number) => {
    if (type === 1) {
      this.effectTIme = true;
    } else {
      this.effectTIme = false;
    }
  };

  @action public getMealList = async (type) => {
    let functionType = [];
    if (type == 1) {
      functionType = [9, 13, 14];
    }
    if (type == 2) {
      functionType = [7, 12];
    }
    return request<BaseData<any>>({
      url: '/pbb/platform/sys/service/package/simple/list',
      method: 'POST',
      data: {
        functionType,
      },
    }).then((res) => {
      this.projectList = transformKeys(res.data, {
        name: 'label',
        id: 'value',
      });
    });
  };
}
